import React from "react";
// import TechPageComp from "../../../components/TechPageComp/TechPageComp";
import "./cij.scss";
// import { useCodingState } from "../../../service/CodingProvider";
import TechPageApiComp from "../../../components/TechPageApiComp/TechPageApiComp";

function MobileCIJ() {
  return <TechPageApiComp defaultSetUser={true} isMobile={true} />;
}

export default MobileCIJ;
