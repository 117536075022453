import Modal from 'react-bootstrap/Modal';
import ButtonComp from '../button/ButtonComp';
import './modal-comp.scss';

function ModalComp({
  children,
  onHide,
  show,
  primaryFunc,
  secondFunc,
  thirdFunc,
  buttonText,
  ModalHeader,
  isPrimary,
  isSecondary,
  isThird,
  withBtn,
  withoutCloseButton,
  centerTitle,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={withoutCloseButton ? false : true}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={centerTitle ? 'center-title' : ''}
        >
          {ModalHeader}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {withBtn && (
        <Modal.Footer>
          {isThird && (
            <ButtonComp clickFunc={thirdFunc} buttonType={'button'} third>
              {buttonText.third}
            </ButtonComp>
          )}
          {isSecondary && (
            <ButtonComp clickFunc={secondFunc} buttonType={'button'} secondary>
              {buttonText.secondary}
            </ButtonComp>
          )}
          {isPrimary && (
            <ButtonComp clickFunc={primaryFunc} buttonType={'button'} primary>
              {buttonText.primary}
            </ButtonComp>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalComp;
