import React from "react";
import Header from "../Header/Header";
import "./solution-tech.scss";
import backButton from "../../assets/back-button.svg";
import { useNavigate } from "react-router-dom";

function SolutionTech({
  techData,
  clickAction,
  what,
  host,
  backButtonVisible = false,
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/mobile");
  };

  return (
    <div className="SolutionTech">
      <Header white />
      <div className="technology-text">
        <div className="tecnology-wrapper">
          {backButtonVisible && (
            <div className="back-container">
              <span onClick={() => handleNavigate()}>
                <img src={backButton} alt="back button" />
              </span>
            </div>
          )}
          <div className="text-container">
            {what === "coding" ? (
              <>
                <h1>Coding & Marking</h1>
                <p>Technologies</p>
              </>
            ) : (
              <>
                <h1>packaging machine</h1>
                <p>Technologies</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="tech-card-container">
        <div className={"technologies-card"}>
          {techData.map((v, idx) => (
            <div
              key={idx}
              className="image-wrapper"
              onClick={() => {
                if (v.technologyName !== "Egg Roll Processing Machine") {
                  clickAction(v);
                }
              }}>
              <div
                className={
                  what === "coding"
                    ? "solution-card-image"
                    : "solution-card-image-packaging solution-card-image"
                }>
                <img
                  crossOrigin="anonymous"
                  src={`${v.imageTechnology}`}
                  alt={v.slugName}
                />
              </div>
              <div className="solution-card-text">
                <h2 className="h2-solution-tech">{v.technologyName}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SolutionTech;
