import React from "react";
import "./company-overview.scss";

function CompanyOverview() {
  return (
    <div className="CompanyOverview">
      <div className="company-overview-card">
        <div className="overview-item">
          <p className="overview-num">11</p>
          <p className="overview-text">Brands</p>
        </div>
        <div className="overview-item">
          <p className="overview-num">18</p>
          <p className="overview-text">Technologies</p>
        </div>
        <div className="overview-item">
          <p className="overview-num">300+</p>
          <p className="overview-text">Machines</p>
        </div>
        <div className="overview-item">
          <p className="overview-num">7</p>
          <p className="overview-text">Office Branch</p>
        </div>
        <div className="overview-item">
          <p className="overview-num">15k+</p>
          <p className="overview-text">Machines Installed</p>
        </div>
      </div>
    </div>
  );
}

export default CompanyOverview;
