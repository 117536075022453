import React from "react";
import OfficeBranchCard from "../OfficeBranchCard/OfficeBranchCard";
import "./branch-card.scss";

const branchData = [
  { branchName: "Surabaya" },
  { branchName: "Semarang" },
  { branchName: "Medan" },
  { branchName: "Bandung" },
  { branchName: "Makasar" },
  { branchName: "Cikarang" },
];
function BranchCard() {
  return (
    <div className="BranchCard">
      <h3 className="branch-card-title">Branches Office</h3>
      <div className="branch-cards">
        {branchData.map((v, idx) => (
          <div key={idx}>
            <OfficeBranchCard>
              <div className="branch-card-text">{v.branchName}</div>
            </OfficeBranchCard>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BranchCard;
