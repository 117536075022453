import React from 'react';
// import TechPageComp from "../../../components/TechPageComp/TechPageComp";
import './cij.scss';
// import { useCodingState } from "../../../service/CodingProvider";
import TechPageApiComp from '../../../components/TechPageApiComp/TechPageApiComp';

function CIJ() {
  // return <TechPageComp numSolution={0} what={"Continuous Ink Jet"} />;
  return <TechPageApiComp />;
}

export default CIJ;
