import "./styles/App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage1 from "./pages/landingPage1/LandingPage1";
import LandingPageGeneral from "./pages/landingPageGeneral/LandingPageGeneral";
import ErrorPage from "./pages/errorPage/ErrorPage";
import Coding from "./pages/Solutions/Coding";
import Packaging from "./pages/Solutions/Packaging";
import CIJ from "./pages/technologies/TechCIJ/CIJ";
// import TechLabel from "./pages/technologies/TechLabel/TechLabel";
// import TechLCM from "./pages/technologies/TechLCM/TechLCM";
// import TechLM from "./pages/technologies/TechLM/TechLM";
// import TechTIJ from "./pages/technologies/TechTIJ/TechTIJ";
// import TechTTO from "./pages/technologies/TechTTO/TechTTO";
// import TechTam from "./pages/technologies/TechTam/TechTam";
// import TechYamato from "./pages/technologies/TechYamato/TechYamato";
// import TechSS from "./pages/technologies/TechSS/TechSS";
// import TechFuji from "./pages/technologies/TechFuji/TechFuji";
// import TechUnipack from "./pages/technologies/TechUnipack/TechUnipack";
// import TechHerma from "./pages/technologies/TechHerma/TechHerma";
// import TechProdec from "./pages/technologies/TechProdec/TechProdec";
// import TechMespack from "./pages/technologies/TechMespack/TechMespack";
// import TechKitamura from "./pages/technologies/TechKitamura/TechKitamura";
import { CodingProvider } from "./service/CodingProvider";
import CodingMobile from "./pages/Solutions/CodingMobile";
import LandingPageMobile from "./pages/landingPage1/LandingPageMobile";
import MobileCIJ from "./pages/technologies/TechCIJ/MobileCIJ";
import PackagingMobile from "./pages/Solutions/PackagingMobile";

function App() {
  return (
    <div className="App">
      <CodingProvider>
        <BrowserRouter>
          <Routes>
            <Route path={"/"}>
              <Route path={""} element={<LandingPage1 />} />
              <Route path={"general"} element={<LandingPageGeneral />} />
              <Route path={"visitor"}>
                <Route path={":usernum"} element={<LandingPage1 />} />{" "}
              </Route>
              <Route path={"solution/"}>
                <Route path={"coding"} element={<Coding />} />
                <Route path={"packaging"} element={<Packaging />} />
              </Route>

              <Route path={"technologies/:product"} element={<CIJ />} />

              {/* <Route path={"continuous-ink-jet"} element={<CIJ />} */}

              {/* <Route path={"technologies/"}>
                <Route path={"continuous-ink-jet"} element={<CIJ />} />

                <Route
                  path={"label-print-and-applicators"}
                  element={<TechLabel />}
                />
                <Route path={"large-character-marking"} element={<TechLCM />} />
                <Route path={"laser-marking"} element={<TechLM />} />
                <Route path={"thermal-ink-jet"} element={<TechTIJ />} />
                <Route
                  path={"thermal-transfer-overprinters"}
                  element={<TechTTO />}
                />
                <Route path={"VFFS-Machine"} element={<TechTam />} />
                <Route
                  path={"Dataweigh-Checkweigher"}
                  element={<TechYamato />}
                />
                <Route path={"X-Ray-and-Metal-Detector"} element={<TechSS />} />
                <Route path={"horizontal-wrapper"} element={<TechFuji />} />
                <Route
                  path={"sachet-multilane-machine"}
                  element={<TechUnipack />}
                />
                <Route path={"label-applicators"} element={<TechHerma />} />
                <Route path={"end-of-line-solution"} element={<TechProdec />} />
                <Route
                  path={"standing-pouch-machine"}
                  element={<TechMespack />}
                />
                <Route
                  path={"egg-roll-processing-machine"}
                  element={<TechKitamura />}
                />
              </Route> */}
            </Route>

            {/* ROUTE APPS MOBILE */}
            <Route path={"/mobile"}>
              <Route path={""} element={<LandingPageMobile />} />
              <Route path={"solution/"}>
                <Route path={"coding"} element={<CodingMobile />} />
                <Route path={"packaging"} element={<PackagingMobile />} />
              </Route>

              <Route path={"technologies/:product"} element={<MobileCIJ />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </CodingProvider>
    </div>
  );
}

export default App;
