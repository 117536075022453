import { createContext, useReducer, useContext } from "react";

// Step 1: Create a Context
const CodingStateContext = createContext();
const CodingDispatchContext = createContext();

// Step 2: Create a reducer function
const codingReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA_ALL_TECHNOLOGY":
      return { ...state, dataAllTechnology: action.payload };
    case "SET_DATA_TECHNOLOGY":
      return { ...state, dataTechnology: action.payload };
    case "SET_DATA_PRODUCT":
      return { ...state, dataProduct: action.payload };
    default:
      return state;
  }
};

// Step 3: Create a provider component
const CodingProvider = ({ children }) => {
  const initialStateCoding = {
    dataAllTechnology: [],
    dataTechnology: {},
    dataProduct: [],
  };

  const [state, dispatch] = useReducer(codingReducer, initialStateCoding);
  return (
    <CodingStateContext.Provider value={state}>
      <CodingDispatchContext.Provider value={dispatch}>
        {children}
      </CodingDispatchContext.Provider>
    </CodingStateContext.Provider>
  );
};

// Step 4: Custom hook for accessing global state
const useCodingState = () => {
  const context = useContext(CodingStateContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalProvider");
  }
  return context;
};

// Step 5: Custom hook for accessing global dispatch
const useCodingDispatch = () => {
  const context = useContext(CodingDispatchContext);
  if (context === undefined) {
    throw new Error("useGlobalDispatch must be used within a GlobalProvider");
  }
  return context;
};

export { CodingProvider, useCodingState, useCodingDispatch };
