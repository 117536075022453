import React from "react";
import logo from "../../assets/markindo-logo.svg";
import "./loading-style.scss";
const LoadingComp = () => {
  return (
    <div className="loading-container">
      <img className={"loading-image"} src={logo} alt={"logo pt markindo"} />
    </div>
  );
};

export default LoadingComp;
