import React from "react";
import "./header.scss";
import logoBlue from "../../assets/markindo-logo.svg";
import logo from "../../assets/mkd-logo-white.png";

function Header({ white, blue }) {
  return (
    <div className="Header">
      {white && <img src={logoBlue} alt={"logo pt markindo"} />}
      {blue && (
        <img className={"logo-mkd"} src={logo} alt={"logo pt markindo"} />
      )}
    </div>
  );
}

export default Header;
