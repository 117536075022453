import React, { useEffect, useState } from 'react';
// import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ButtonComp from '../button/ButtonComp';
import backButton from '../../assets/back-button.svg';
import './tech-page-api-comp.scss';
import { baseUrl } from '../../service/baseUrl';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import {
  useCodingDispatch,
  useCodingState,
} from '../../service/CodingProvider';
import { useNavigate } from 'react-router-dom';
import { axiosFunc } from '../../lib/AxiosFunc/AxiosFunc';
import AlertComp from '../AlertComp/AlertComp';

const TechPageApiComp = ({ defaultSetUser = false, isMobile = false }) => {
  const [isUser, setIsUser] = useState(defaultSetUser);
  const [productDetail, setProductDetail] = useState(false);
  const [detailProduct, setDetailProduct] = useState({});
  const [waPhoneNumberChatbot, setWaPhoneNumberChatbot]  =  useState();
  const [waPhoneNumberCS, setWaPhoneNumberCS]  =  useState();
  // console.log(detailProduct);
  //   const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const state = useCodingState();
  const dispatch = useCodingDispatch();
  const navigate = useNavigate();
  const dataProduct = state.dataProduct;
  const dataTechnology = state?.dataAllTechnology;
  const typeTech = localStorage.getItem('typeTech');
  const userPhoneNum = localStorage.getItem('usernum');
  const getIdTechnology = localStorage.getItem('idTech');

  const checkUserName = async () => {
    const url = `${baseUrl}/user/check-user/${userPhoneNum}`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    // const encSecret = window.btoa(rahasia);
    // console.log(encSecret);
    const response = await axios
      .get(url, {
        headers: { Authorization: rahasia },
      })
      .catch((e) => {
        console.log(e.response);
        setIsUser(false);
      });
    if (response?.data.data.user[0]?.userName) {
      console.log(response);
      setIsUser(true);
    }
  };

  const getProduct = async () => {
    // reset value
    dispatch({ type: 'SET_DATA_PRODUCT', payload: [] });

    // fetching data
    const url = `${baseUrl}/v1/technology/technlogy-subproduct-master`;
    const rahasia = process.env.REACT_APP_RAHASIA;
    const response = await axios
      .get(url, {
        headers: { Authorization: rahasia },
        params: {
          technologyId: getIdTechnology,
        },
      })
      .catch((e) => {
        console.log(e.response);
        setIsUser(false);
      });
    if (response.status === 200) {
      dispatch({ type: 'SET_DATA_PRODUCT', payload: response?.data?.data });
    }
  };

  const fetchDataTech = async () => {
    const url = `/v1/technology/technlogy-master`;
    const token = process.env.REACT_APP_RAHASIA;
    setAlert(false);
    const data = {
      params: {
        typeTechnology: typeTech,
      },
      token: token,
    };
    const res = await axiosFunc('get', url, data);
    if (res.status === 200) {
      const data = res.data.data;
      dispatch({ type: 'SET_DATA_ALL_TECHNOLOGY', payload: data });
      return;
    } else {
      setTimeout(() => {
        setAlert(true);
      }, [10000]);
      console.log('something went wrong');
    }
  };

  const fetchOneTech = async () => {
    const url = `/v1/technology/technlogy-find-one`;
    const token = process.env.REACT_APP_RAHASIA;
    const data = {
      params: {
        idTechnology: getIdTechnology,
      },
      token: token,
    };
    const res = await axiosFunc('get', url, data);
    if (res.status === 200) {
      const data = res.data.data;
      dispatch({ type: 'SET_DATA_TECHNOLOGY', payload: data });
      return;
    } else {
      setTimeout(() => {
        setAlert(true);
      }, [10000]);
      console.log('something went wrong');
    }
  };
  const getWaPhoneNumberChatbot = async()=>{
    const rahasia = process.env.REACT_APP_RAHASIA_SD;
    const decSecret = window.btoa(rahasia);
    const url = 'phonenumberlist/where';
    const options = {label: 'no-whatsapp-markindo'};
    const response = await axios({
      method: 'get',
      url: `${baseUrl}/${url}`,
      params: options,
      headers: { Authorization: decSecret },
    }).catch((e) => {
        console.log(e);
      });
      setWaPhoneNumberChatbot(response.data.data.phoneNumber);
      console.log(response)
  }
  const getWaPhoneNumberCs = async()=>{
    const rahasia = process.env.REACT_APP_RAHASIA_SD;
    const decSecret = window.btoa(rahasia);
    const url = 'phonenumberlist/where';
    const options = {label: 'no-whatsapp-cs'};
    const response = await axios({
      method: 'get',
      url: `${baseUrl}/${url}`,
      params: options,
      headers: { Authorization: decSecret },
    }).catch((e) => {
        console.log(e);
      });
      setWaPhoneNumberCS(response.data.data.phoneNumber);
      console.log(response)
  }

  const clickToWa = (tech) => {
    window.location.href = `https://wa.me/${waPhoneNumberChatbot}?text=Kirimkan%20list%20produk%20lebih%20detail%20${tech}`;
  };
  const clickToSite = (site) => {
    window.location.href = site;
  };
  const clickLearnMore = (site) => {
    window.location.href = site;
  };

  const handleSelect = (item) => {
    // console.log(item, "SELECT ITEM");

    localStorage.setItem('idTech', item.id);
    localStorage.setItem('typeTech', item.typeTechnology);
    setTimeout(() => {
      if (isMobile) {
        window.location.href = `/mobile/technologies/${item.slugName}`;
      } else {
        window.location.href = `/technologies/${item.slugName}`;
      }
    }, 200);
  };

  const handleNavigateBack = () => {
    if (defaultSetUser) {
      navigate(`/mobile/solution/${state.dataTechnology?.typeTechnology}`);
    } else {
      navigate(`/solution/${state.dataTechnology?.typeTechnology}`);
    }
  };

  useEffect(() => {
    getWaPhoneNumberCs();
    getWaPhoneNumberChatbot();
    checkUserName();
    getProduct();

    if (dataTechnology.length === 0) {
      fetchOneTech();
      fetchDataTech();
    }
    console.log('cs',waPhoneNumberCS);
    console.log('markindo', waPhoneNumberChatbot)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, [100]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="TechPageComp">
      <header>
        {isUser ? (
          <>
            <div className="after-login-header">
              <span
                onClick={() => {
                  productDetail
                    ? setProductDetail(false)
                    : handleNavigateBack();
                }}
              >
                <img src={backButton} alt="back button" />
              </span>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {state.dataTechnology?.technologyName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {state.dataAllTechnology?.map((value, index) => {
                    return (
                      <div key={value.id}>
                        <Dropdown.Item onClick={() => handleSelect(value)}>
                          <span
                            className={`${
                              value.technologyName ===
                              state.dataTechnology?.technologyName
                                ? 'bold'
                                : ''
                            }`}
                          >
                            {value.technologyName}
                          </span>
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="tech-description-container">
              <p>{state.dataTechnology?.descTechnology}</p>
            </div>
          </>
        ) : (
          <>
            <Header blue />
            <div className="before-login-container">
              <h1>Coding Marking</h1>
              <p>Technologies</p>
            </div>
          </>
        )}
      </header>

      {alert && (
        <AlertComp
          errorText={'Something went wrong'}
          onClick={() => fetchDataTech()}
        />
      )}

      {productDetail ? (
        <section className="main-content-tech">
          <div className="product-detail-tech">
            <div className="product-detail-card">
              <div className="product-detail-header">
                {/* {productsData[0].name === "Mespack" ||
             productsData[0].name === "Prodec" ? null : (
               <img
                 className={
                   solutionType.solutionType === "Coding Marking"
                     ? "image-product-detail-coding"
                     : "image-product-detail"
                 }
                 src={detailProduct?.img}
                 alt="product"
               />
             )} */}

                <img
                  className={'image-product-detail-coding'}
                  src={`${detailProduct.imageProduct}`}
                  alt="product"
                />
                <div className="solution-card-text">
                  <h2 className="h2-solution-card">
                    {detailProduct?.nameProduct}
                  </h2>
                  <p>{detailProduct?.desciptionProduct}</p>
                </div>
              </div>
              <div className="product-detail-footer">
                <div className="button-top">
                  <ButtonComp
                    clickFunc={() => {
                      clickToSite(detailProduct?.linkWeb);
                    }}
                    buttonType={'button'}
                    primary
                  >
                    WEBSITE
                  </ButtonComp>
                  <ButtonComp
                    clickFunc={() => {
                      clickToSite(detailProduct?.linkDownload);
                    }}
                    buttonType={'button'}
                    primary
                  >
                    DOWNLOAD
                  </ButtonComp>
                </div>
                <div className="button-bottom">
                  <ButtonComp
                    clickFunc={() => {
                      const waNumber = waPhoneNumberCS;
                      const productName = detailProduct?.nameProduct;
                      const nameProductArray = productName.split(' ');
                      let stringName;
                      nameProductArray.map((nameProduct, idx) => {
                        if (idx === 0) {
                          stringName = nameProduct;
                        } else {
                          stringName = `${stringName}` + `+${nameProduct}`;
                        }
                      });
                      const linkWaValue = `https://api.whatsapp.com/send/?phone=${waNumber}&text=Halo+PT.+Markindo+Rekateknik+saya+ingin+bertanya+mengenai+produk+${stringName}&type=phone_number&app_absent=0`;
                      clickToSite(linkWaValue);
                    }}
                    buttonType={'button'}
                    primary
                  >
                    HUBUNGI WHATSAPP
                  </ButtonComp>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="main-content-tech">
          {isUser ? (
            <div className="main-content-container">
              <div className="technologies-card">
                {dataProduct.map((value) => (
                  <div
                    key={value.id}
                    className="image-wrapper"
                    onClick={() => {
                      setDetailProduct(value);
                      setProductDetail(true);
                    }}
                  >
                    <div
                      className={
                        state.dataTechnology.typeTechnology === 'coding'
                          ? 'solution-card-image-packaging solution-card-image'
                          : 'solution-card-image-packaging solution-card-image'
                      }
                    >
                      <img
                        src={`${value.imageProduct}`}
                        alt={value.nameProduct}
                      />
                    </div>
                    <div className="solution-card-text">
                      <h2 className="h2-solution-card">{value.nameProduct}</h2>
                    </div>
                  </div>
                ))}
                <div
                  className={
                    dataProduct.length % 2 === 1
                      ? 'learn-more-button'
                      : 'learn-more-button learn-more-span1'
                  }
                >
                  <ButtonComp
                    buttonType={'button'}
                    primary
                    clickFunc={() => {
                      clickLearnMore(state.dataTechnology.linkLearnMore);
                    }}
                  >
                    LEARN MORE
                  </ButtonComp>
                </div>
              </div>
            </div>
          ) : (
            <div className="lanjut-wa-card-container">
              <div className="lanjut-ke-wa">
                <div className="line-style"></div>
                <div className="wa-card-title">Hubungi WhatsApp Kami</div>
                <div className="wa-card-text">
                  Anda akan terhubung ke layanan WhatsApp PT. Markindo
                  Rekateknik untuk mendapatkan informasi lebih lanjut mengenai
                  produk unggulan kami.
                </div>
                <div className="wa-card-button">
                  <ButtonComp
                    buttonType={'button'}
                    third
                    clickFunc={() => {
                      navigate('/');
                    }}
                  >
                    KEMBALI
                  </ButtonComp>
                  <ButtonComp
                    buttonType={'button'}
                    primary
                    clickFunc={() => {
                      clickToWa(state.dataTechnology?.slugName);
                    }}
                  >
                    LANJUTKAN
                  </ButtonComp>
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default TechPageApiComp;
