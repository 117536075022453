import React from "react";
import "./alert-style.scss";
const AlertComp = ({ errorText, onClick, btnText = "Reload" }) => {
  return (
    <div className="floating-alert">
      <div className="floating-reload">
        <p className="floating-text">{errorText}</p>
        <button className="refresh-button" onClick={onClick}>
          {btnText}
        </button>
      </div>
    </div>
  );
};

export default AlertComp;
