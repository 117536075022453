import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cij from '../../assets/cij.png';
import tij from '../../assets/tij.png';
import tto from '../../assets/tto.png';
import lm from '../../assets/lm.png';
import lcm from '../../assets/lcm.png';
import label from '../../assets/label.png';
import tam from '../../assets/tam.png';
import yamato from '../../assets/yamato.png';
import ss from '../../assets/ss.png';
import fuji from '../../assets/fuji.png';
import unipack from '../../assets/unipack.png';
import herma from '../../assets/herma.png';
import prodec from '../../assets/prodec.png';
import mespack from '../../assets/mespack.png';
import kitamura from '../../assets/kitamura.png';
import arrow from '../../assets/arrow_back.svg';
import vjlogo from '../../assets/Videojet-Logo.png';
import './landing-page1.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ModalComp from '../../components/modal/ModalComp';
import BodyModalCreate from './BodyModalRegistration';
import RegistrationModal from './BodyModalRegistration';
import LoadingComp from '../../components/LoadingComp/LoadingComp';

const data = [
  {
    solution: 'Coding & Marking',
  },
  {
    solution: 'Packaging Machines',
  },
];
const dataTechCoding = [
  {
    name: 'Continous Ink Jet',
    img: cij,
  },
  {
    name: 'Thermal Ink Jet',
    img: tij,
  },
  {
    name: 'Thermal Transfer Overprinter',
    img: tto,
  },
  {
    name: 'Laser Marking',
    img: lm,
  },
  {
    name: 'Large Character Marking',
    img: lcm,
  },
  {
    name: 'Label Print & Applicators',
    img: label,
  },
];
const dataTechPack = [
  {
    name: 'X-Ray & Metal Detector',
    img: ss,
  },
  {
    name: 'Dataweigh - Checkweigher',
    img: yamato,
  },
  {
    name: 'Sachet Multilane Machine',
    img: unipack,
  },
  {
    name: 'Horizontal Wrapper',
    img: fuji,
  },
  {
    name: 'VFFS Machine',
    img: tam,
  },
  {
    name: 'Standing Pouch Machine',
    img: mespack,
  },
  {
    name: 'End of Line Solution',
    img: prodec,
  },
  {
    name: 'Label Applicators',
    img: herma,
  },
  {
    name: 'Egg Roll Processing Machine',
    img: kitamura,
  },
];

function LandingPage1() {
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [failedDialog, setFailedDialog] = useState(false);
  const [successDialogMsg, setSuccessDialogMsg] = useState(
    'Registrasi berhasil, trima kasih!'
  );
  const [failedDialogMsg, setFailedDialogMsg] = useState(
    'Silakan cek koneksi anda'
  );
  const [showModalSignin, setShowModalSignin] = useState(true);
  const navigate = useNavigate();
  let { usernum } = useParams();
  const userNumber = localStorage.getItem('usernum');
  useEffect(() => {
    if (usernum) {
      const decNum = window.atob(usernum);
      localStorage.setItem('usernum', decNum);
    }
    if (userNumber) {
      setShowModalSignin(false);
    }
  }, [usernum]);
  // loading ? (
  //   <LoadingComp />
  // ) :
  return (
    <>
      <div className="LandingPage1">
        <div className="landing-page-image">
          <Header blue />
        </div>
        <h5 className="welcome-text">Click For More Information</h5>
        {data.map((v, idx) => {
          return (
            <div key={idx}>
              <div
                onClick={
                  v.solution === 'Coding & Marking'
                    ? () => navigate('/solution/coding')
                    : () => navigate('/solution/packaging')
                }
                className={
                  v.solution === 'Coding & Marking'
                    ? 'solution-card-click solution-coding-height'
                    : 'solution-card-click'
                }
              >
                {v.solution === 'Coding & Marking' && (
                  <div className="coding-label-style">CODING & MARKING</div>
                )}
                {v.solution === 'Packaging Machines' && (
                  <div className="coding-label-style coding-label-style-packaging">
                    PACKAGING MACHINES
                  </div>
                )}
                <div key={idx} className="solution-image-container">
                  {v.solution === 'Coding & Marking' &&
                    dataTechCoding.map((v, idx) => (
                      <div key={idx} className="image-wrapper">
                        <img
                          className="solution-card-image"
                          src={v.img}
                          alt={v.name}
                        />
                        <h2>{v.name}</h2>
                      </div>
                    ))}
                  {v.solution === 'Coding & Marking' && (
                    <div className="vj-logo-wraper">
                      <img
                        className="videojet-logo-card"
                        src={vjlogo}
                        alt={'logo video jet'}
                      />
                    </div>
                  )}
                  {v.solution === 'Packaging Machines' &&
                    dataTechPack.map((v, idx) => (
                      <div key={idx} className="image-wrapper">
                        <img
                          className="solution-card-image-packaging"
                          src={v.img}
                          alt={v.name}
                        />
                        <h2>{v.name}</h2>
                      </div>
                    ))}
                </div>
                <img
                  src={arrow}
                  alt={'arrow to next page'}
                  className={
                    v.solution === 'Coding & Marking'
                      ? 'arrow-style-coding'
                      : 'arrow-style-pack'
                  }
                />
              </div>
              {idx === 0 && <div className="or-style">OR</div>}
            </div>
          );
        })}
      </div>
      <Footer />
      {/* <ModalComp
        withoutCloseButton
        centerTitle
        show={showModalSignin}
        onHide={() => setShowModalSignin}
        ModalHeader={'Register here'}
        buttonText={{ primary: 'Create' }}
      >
        <RegistrationModal
          setLoading={setLoading}
          setSuccessDialog={setSuccessDialog}
          setFailedDialog={setFailedDialog}
          setShowModalSignin={setShowModalSignin}
          setSuccessDialogMsg={setSuccessDialogMsg}
          setFailedDialogMsg={setFailedDialogMsg}
        />
      </ModalComp> */}
      <ModalComp
        centerTitle
        show={successDialog}
        onHide={() => setSuccessDialog(!successDialog)}
        ModalHeader={'Berhasil'}
        buttonText={{ primary: 'Create' }}
      >
        <center>{successDialogMsg}</center>
      </ModalComp>
      <ModalComp
        centerTitle
        show={failedDialog}
        onHide={() => setFailedDialog(!failedDialog)}
        ModalHeader={'Gagal!'}
        buttonText={{ primary: 'Create' }}
      >
        <center>{failedDialogMsg}</center>
      </ModalComp>
    </>
  );
}

export default LandingPage1;
