import { Link } from "react-router-dom";
import "./error-page.scss";

function ErrorPage() {
  return (
    <div className="error-container">
      <h1>No found path turn around!</h1>
      <Link to="/">Back to home</Link>
    </div>
  );
}

export default ErrorPage;
