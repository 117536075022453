import React from "react";
import "./head-office-card.scss";

function HeadOfficeCard() {
  return (
    <div className="HeadOfficeCard">
      <div className="head-office-card">
        <div className="line-style"></div>
        <h1>Head Office - Jakarta</h1>
        <p>
          Graha Arteri Mas 2, Jl. Panjang No. 68 Kav 6 Jakarta Barat, Jakarta
          Barat – DKI Jakarta
        </p>
      </div>
    </div>
  );
}

export default HeadOfficeCard;
