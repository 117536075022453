import React from "react";
import "./about-us.scss";
import logoM from "../../assets/logo-m.svg";

function AboutUs() {
  return (
    <div className="AboutUs">
      <div className="about-us-header">
        <div className="markindo-text">
          <h4 className="text-about">About</h4>
          <h2 className="markindo-logo-text">Markindo Rekateknik</h2>
        </div>
        <div className="markindo-logo">
          <img src={logoM} alt="markindo logo" />
        </div>
      </div>
      <div className="about-us-text">
        For 25 years PT. Markindo Rekateknik has been providing Indonesia’s
        small-large businesses with the most innovative Packaging, Coding, and
        Marking solutions, technologies, and services.
        <br />
        <br />
        With passion and dedication to support the ever growing needs for
        creative Packaging, Coding, and Marking, PT. Markindo Rekateknik
        partners up with internationally well acclaimed and most experienced
        brands to put forward the broadest, cutting-edge, efficient, cost
        effective solutions and technologies.
      </div>
    </div>
  );
}

export default AboutUs;
