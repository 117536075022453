import React from "react";
import "./office-branch-card.scss";

function OfficeBranchCard({ children }) {
  return (
    <div className="OfficeBranchCard">
      <div className="line-style"></div>
      {children}
    </div>
  );
}

export default OfficeBranchCard;
