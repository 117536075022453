import React, { useState } from 'react';
import './button-component.scss';

function ButtonComp({
  children,
  clickFunc,
  type,
  primary,
  secondary,
  third,
  disable,
}) {
  return (
    <>
      {primary && (
        <button onClick={clickFunc} type={type} className="primary-btn">
          {children}
        </button>
      )}
      {secondary && (
        <button onClick={clickFunc} type={type} className="secondary-btn">
          {children}
        </button>
      )}
      {third && (
        <button onClick={clickFunc} type={type} className="third-btn">
          {children}
        </button>
      )}
      {disable && (
        <button onClick={clickFunc} type={type} className="disable-btn">
          {children}
        </button>
      )}
    </>
  );
}

export default ButtonComp;
function ButtonCompNew({
  onClick,
  children,
  type,
  variant,
  debounceTime = 2000,
  disabled = false,
}) {
  const [isClickPending, setIsClickPending] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(disabled);
  // console.log(type, "FROM BOTTON");

  const handleButtonClick = () => {
    if (type === 'submit') {
      return;
    }

    if (!isClickPending) {
      setDisabledBtn(true);
      setIsClickPending(true);
      onClick();
      setTimeout(() => {
        setIsClickPending(false);
        setDisabledBtn(false);
      }, debounceTime);
    }
  };

  let className = 'primary-btn';
  if (variant === 'primary') {
    className = 'primary-btn';
  }
  if (variant === 'secondary') {
    className = 'secondary-btn';
  }
  if (variant === 'third') {
    className = 'third-btn';
  }

  return (
    <button
      disabled={disabledBtn}
      onClick={handleButtonClick}
      type={type}
      className={className}
    >
      {children}
    </button>
  );
}

export { ButtonCompNew };
