import React from "react";
import "./footer.scss";

function Footer() {
  return (
    <div className="Footer">
      <p className="footer-text">
        Copyright © 2022 PT. Markindo Rekateknik. All Rights Reserved. By
        accessing our site, you are agreed to our User Agreement, Your Indonesia
        Privacy Rights, Privacy Policy, and Cookie Statements.
      </p>
    </div>
  );
}

export default Footer;
