import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import SolutionTech from "../../components/SolutionsTech/SolutionTech";

import "./solutions-coding.scss";
import SpecialOffers from "../../components/SpecialOffers/SpecialOffers";
import HeadOfficeCard from "../../components/HeadOfficeCard/HeadOfficeCard";
import CompanyOverview from "../../components/CompanyOverview/CompanyOverview";
import AboutUs from "../../components/AboutUs/AboutUs";
import BranchCard from "../../components/BranchCard/BranchCard";
import { useNavigate } from "react-router-dom";

import LoadingComp from "../../components/LoadingComp/LoadingComp";
import AlertComp from "../../components/AlertComp/AlertComp";
import { axiosFunc } from "../../lib/AxiosFunc/AxiosFunc";
import { useCodingDispatch } from "../../service/CodingProvider";

function CodingMobile() {
  const navigate = useNavigate();

  const [dataTechCoding, setDataTechCoding] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const dispatch = useCodingDispatch();
  const host = process.env.REACT_APP_API_URL;

  const goToTech = (tech) => {
    dispatch({ type: "SET_DATA_TECHNOLOGY", payload: tech });
    localStorage.setItem("idTech", tech.id);
    localStorage.setItem("typeTech", "coding");
    setTimeout(() => {
      navigate(`/mobile/technologies/${tech.slugName}`);
    }, 200);
  };

  const fetchData = async () => {
    // reset state
    dispatch({ type: "SET_DATA_TECHNOLOGY", payload: [] });

    // fetching data
    const url = `/v1/technology/technlogy-master`;
    const token = "bXktc2VjcmVhdC1hbGxwYWNrLXRva2Vu";
    setAlert(false);
    setLoading(true);
    const data = {
      params: {
        typeTechnology: "coding",
      },
      token: token,
    };
    const res = await axiosFunc("get", url, data);
    if (res?.status === 200) {
      const data = res.data.data;
      setDataTechCoding(data);
      dispatch({ type: "SET_DATA_ALL_TECHNOLOGY", payload: data });
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
      return;
    } else {
      setTimeout(() => {
        setAlert(true);
      }, [10000]);
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [100]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Coding">
      {alert && (
        <AlertComp
          errorText={"Something went wrong"}
          onClick={() => fetchData()}
        />
      )}
      {loading ? (
        <LoadingComp />
      ) : (
        <SolutionTech
          techData={dataTechCoding}
          clickAction={goToTech}
          what={"coding"}
          host={host}
          backButtonVisible={true}
        />
      )}

      <SpecialOffers />
      <div className="overview-container">
        <CompanyOverview />
      </div>
      <div className="footer-section">
        <div className="about-us-container">
          <AboutUs />
        </div>
        <HeadOfficeCard />
        <BranchCard />
        <Footer />
      </div>
    </div>
  );
}

export default CodingMobile;
