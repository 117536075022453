import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import SolutionTech from "../../components/SolutionsTech/SolutionTech";
// import tam from "../../assets/tam.png";
// import yamato from "../../assets/yamato.png";
// import ss from "../../assets/ss.svg";
// import fuji from "../../assets/fuji.svg";
// import unipack from "../../assets/unipack.png";
// import herma from "../../assets/herma.svg";
// import prodec from "../../assets/prodec.svg";
// import mespack from "../../assets/mespack.svg";
// import kitamura from "../../assets/kitamura.png";
import "./solutions-packaging.scss";
import HeadOfficeCard from "../../components/HeadOfficeCard/HeadOfficeCard";
import CompanyOverview from "../../components/CompanyOverview/CompanyOverview";
import AboutUs from "../../components/AboutUs/AboutUs";
import BranchCard from "../../components/BranchCard/BranchCard";
import { useNavigate } from "react-router-dom";
import { axiosFunc } from "../../lib/AxiosFunc/AxiosFunc";
import AlertComp from "../../components/AlertComp/AlertComp";
import LoadingComp from "../../components/LoadingComp/LoadingComp";
import { useCodingDispatch } from "../../service/CodingProvider";
// const dataTechPack = [
//   {
//     name: "X-Ray & Metal Detector",
//     slugName: "X-Ray-and-Metal-Detector",
//     imageTechnology: ss,
//   },
//   {
//     name: "Dataweigh - Checkweigher",
//     slugName: "Dataweigh-Checkweigher",
//     imageTechnology: yamato,
//   },
//   {
//     name: "Sachet Multilane Machine",
//     slugName: "sachet-multilane-machine",
//     imageTechnology: unipack,
//   },
//   {
//     name: "Horizontal Wrapper",
//     slugName: "horizontal-wrapper",
//     imageTechnology: fuji,
//   },
//   {
//     name: "VFFS Machine",
//     slugName: "VFFS-Machine",
//     imageTechnology: tam,
//   },
//   {
//     name: "Standing Pouch Machine",
//     slugName: "standing-pouch-machine",
//     imageTechnology: mespack,
//   },
//   {
//     name: "Label Applicators",
//     slugName: "label-applicators",
//     imageTechnology: herma,
//   },
//   {
//     name: "End of Line Solution",
//     slugName: "end-of-line-solution",
//     imageTechnology: prodec,
//   },

//   {
//     name: "Egg Roll Processing Machine",
//     slugName: "egg-roll-processing-machine",
//     img: kitamura,
//   },
// ];
function Packaging() {
  const navigate = useNavigate();

  const [dataTechPack, setDataTechPack] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const dispatch = useCodingDispatch();
  const host = process.env.REACT_APP_API_URL;

  const goToTech = (tech) => {
    dispatch({ type: "SET_DATA_TECHNOLOGY", payload: tech });
    localStorage.setItem("idTech", tech.id);
    localStorage.setItem("typeTech", "packaging");
    setTimeout(() => {
      navigate(`/technologies/${tech.slugName}`);
    }, 200);
  };

  const fetchData = async () => {
    // reset state
    dispatch({ type: "SET_DATA_TECHNOLOGY", payload: [] });

    // fetching data
    const url = `/v1/technology/technlogy-master`;
    const token = "bXktc2VjcmVhdC1hbGxwYWNrLXRva2Vu";
    setAlert(false);
    setLoading(true);
    const data = {
      params: {
        typeTechnology: "packaging",
      },
      token: token,
    };
    const res = await axiosFunc("get", url, data);
    if (res?.status === 200) {
      const data = res.data.data;
      setDataTechPack(data);
      dispatch({ type: "SET_DATA_ALL_TECHNOLOGY", payload: data });
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
      return;
    } else {
      setTimeout(() => {
        setAlert(true);
      }, [10000]);
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, [100]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Packaging">
      {alert && (
        <AlertComp
          errorText={"Something went wrong"}
          onClick={() => fetchData()}
        />
      )}
      {loading ? (
        <LoadingComp />
      ) : (
        <SolutionTech
          techData={dataTechPack}
          clickAction={goToTech}
          host={host}
        />
      )}
      {/* <SolutionTech techData={dataTechCoding} clickAction={goToTech} /> */}
      <div className="overview-container">
        <CompanyOverview />
      </div>
      <div className="footer-section">
        <div className="about-us-container">
          <AboutUs />
        </div>
        <HeadOfficeCard />
        <BranchCard />
        <Footer />
      </div>
    </div>
  );
}

export default Packaging;
