import React from 'react';
import './special-offers.scss';

function SpecialOffers() {
  return (
    <div className="SpecialOffers">
      <h1 className="special-offers-title">SPECIAL OFFERS</h1>
      <h5 className="special-offers-desc">
        Get our machines today and enjoy our special offers, including
        <b> Other Free Bonuses!</b>
      </h5>
      <p className="special-offers-toc">*Limited time deals during all pack</p>
    </div>
  );
}

export default SpecialOffers;
