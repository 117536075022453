import axios from "axios";
import { baseUrl } from "../../service/baseUrl";

const host = baseUrl;

const axiosFunc = async (method, url, data, baseURL = host) => {
  let promise;
  if (method === "get") {
    promise = await axios({
      method: "GET",
      url: url,
      params: data?.params,
      baseURL: baseURL,
      headers: {
        Authorization: data?.token,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  return await promise;
};

export { axiosFunc };
